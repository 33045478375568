import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  
  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'email', sortable: true },
    { key: 'fullName', sortable: true },
    { key: 'userRole', sortable: true },
    { key: 'referenceCode', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  
  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  
  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData()
  })
  
  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('userManagement/fetchUsers', {
        Search: searchQuery.value,
        PageSize: perPage.value,
        PageIndex: currentPage.value,
        sortBy: sortBy.value,
        isSortDirDesc: isSortDirDesc.value,
        UserRole: roleFilter.value,
        UserStatus: statusFilter.value,
      })
      .then(response => {
        const { data, count } = response.result;
        
        callback(data)
        totalUsers.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  
  const resolveUserRoleVariant = role => {
    if (role === 0) return 'danger'
    if (role === 1) return 'success'
    if (role === 2) return 'info'
    if (role === 3) return 'warning'
    return 'primary'
  }
  
  const resolveUserRoleIcon = role => {
    if (role === 0) return 'SettingsIcon'
    if (role === 1) return 'UserIcon'
    if (role === 2) return 'Edit2Icon'
    if (role === 3) return 'ServerIcon'
    return 'UserIcon'
  }
  
  const resolveUserStatusVariant = status => {
    if (status === 0) return 'SettingsIcon'
    if (status === 1) return 'UserIcon'
    if (status === 2) return 'Edit2Icon'
    if (status === 3) return 'ServerIcon'
    return 'UserIcon'
  }
  
  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
  
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
  
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
